<template>
  <v-navigation-drawer
    :value='getDrawerVisibility'
    app
    clipped
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-model='listModel'
        active-class='active-item'
      >
        <v-list-item :value='Route.HOME' @click='navigateTo(Route.HOME)'>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('HOME')}}</v-list-item-title>
        </v-list-item>

        <v-subheader v-if='isAdmin'>
          {{$t('STOCK')}}
        </v-subheader>
        <v-list-item
          v-if='isAdmin'
          :value='Route.STOCK'
          @click='navigateTo(Route.STOCK)'
        >
          <v-list-item-icon>
            <v-icon>mdi-toy-brick-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('STOCK_ENTRY_TITLE')}}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if='isAdmin'
          :value='Route.STOCK_SHIPMENT'
          @click='navigateTo(Route.STOCK_SHIPMENT)'
        >
          <v-list-item-icon>
            <v-icon>mdi-toy-brick-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('STOCK_SHIPMENT_TITLE')}}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if='isAdmin'
          :value='Route.STOCK_DISCARD'
          @click='navigateTo(Route.STOCK_DISCARD)'
        >
          <v-list-item-icon>
            <v-icon>mdi-toy-brick-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Descarte</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if='isAdmin'
          :value='Route.REPORTS'
          @click='navigateTo(Route.REPORTS)'
        >
          <v-list-item-icon>
            <v-icon>mdi-toy-brick-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Relatório</v-list-item-title>
        </v-list-item>

        <v-subheader v-if='isAdmin || isValidator'>
          {{$t('DATA')}}
        </v-subheader>
        <v-list-item
          v-if='isAdmin'
          :value='Route.RECORD_SEARCH'
          @click='navigateTo(Route.RECORD_SEARCH)'
        >
          <v-list-item-icon>
            <v-icon>mdi-text-box-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Consulta</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin || isValidator'
          :value='Route.RECORD_VALIDATION'
          @click='navigateTo(Route.RECORD_VALIDATION)'
        >
          <v-list-item-icon>
            <v-icon>mdi-list-status</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Validação</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.IMPORT'
          @click='navigateTo(Route.IMPORT)'
        >
          <v-list-item-icon>
            <v-icon>mdi-download-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Importação</v-list-item-title>
        </v-list-item>

        <v-subheader v-if='isAdmin'>
          {{$t('ADMINISTRATION')}}
        </v-subheader>
        <v-list-item
          v-if='isAdmin && !usePlatform'
          :value='Route.USERS'
          @click='navigateTo(Route.USERS)'
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('USERS')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.CUSTOMERS'
          @click='navigateTo(Route.CUSTOMERS)'
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('CUSTOMERS')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.PRINTERS'
          @click='navigateTo(Route.PRINTERS)'
        >
          <v-list-item-icon>
            <v-icon>mdi-printer</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('PRINTERS')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.IMPORTER'
          @click='navigateTo(Route.IMPORTER)'
        >
          <v-list-item-icon>
            <v-icon>mdi-file-upload-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{$t('IMPORTER')}}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if='isAdmin'
          :value='Route.CONFIG_LAYOUT'
          @click='navigateTo(Route.CONFIG_LAYOUT)'
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Configuração de Layout</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template #append>
      <div class='pa-2'>
        <v-btn text block color='secondary' @click='signOut'>
          {{$t('SIGNOUT')}}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Route } from '@/router';
  import { ACCOUNT_ROLE } from '@/store/modules/Auth';

  export default {
    name: 'AppBarDrawer',
    data: function () {
      return {
        listModel: this.$router.currentRoute.name,
        Route: Route,
      };
    },
    watch: {
      $route: function () {
        this.listModel = this.$router.currentRoute.name || this.$route.name;
      },
    },
    mounted: function () {
      this.listModel = this.$router.currentRoute.name || this.$route.name;
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      ...mapGetters('drawer', [
        'getDrawerVisibility',
      ]),
      isAdmin: function () {
        if (!this.getUser) {
          return false;
        }

        return this.getUser.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN || this.getUser.account.accountRoleId === ACCOUNT_ROLE.ADMIN;
      },
      isValidator: function () {
        if (!this.getUser) {
          return false;
        }

        return this.getUser.account.accountRoleId === ACCOUNT_ROLE.VALIDATOR;
      },
      usePlatform: function () {
        return process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true';
      },
    },
    methods: {
      navigateTo: function (name) {
        if (this.$router.currentRoute.name !== name) {
          this.$router.push({ name: name });
        }
      },
      signOut: async function () {
        await this.$store.dispatch('auth/signOut');

        if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
          window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}`);
        } else {
          this.$router.replace({ name: Route.SIGN_IN });
        }
      },
    },
  };
</script>

<style lang='scss'>
  .active-item {
    background-color: var(--v-accent-base);
    color:var(--v-primary-base) !important;
  }
</style>
